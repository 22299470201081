/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  TextareaAutosize,
  FormControl,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
  useGetFeatures,
  useDeleteFeature,
  useAddFeature,
  useEditFeature
} from '@hooks/useFeatures';
import { DealFeatureType } from '@types';

export const AdminFeatures = ({ ASIN }: { ASIN: string }) => {
  const [editingId, setEditingId] = useState<null | number>(null);
  const [editingFeature, setEditingFeature] = useState<string>('');
  const { data: dealFeatures, refetch: getFeatures } = useGetFeatures(ASIN);
  const { mutate: addFeature } = useAddFeature();
  const [addFeatureOpen, setAddFeatureOpen] = useState(false);
  const { mutate: editFeature } = useEditFeature();
  const { mutate: deleteFeature } = useDeleteFeature();

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <IconButton
          sx={{
            display: 'inline'
          }}
          onClick={() => {
            setAddFeatureOpen(true);
            setEditingId(null);
            setEditingFeature('');
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      {dealFeatures && dealFeatures.length === 0 && (
        <div>
          <Typography variant="body1">No features found</Typography>
        </div>
      )}
      {addFeatureOpen && (
        <Box key="add-feature" sx={{}}>
          <FormControl margin="normal" fullWidth>
            <TextareaAutosize
              minRows={4}
              placeholder="Feature"
              value={editingFeature}
              onChange={(e) => {
                setEditingFeature(e.target.value);
              }}
            />
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '10px 0px 10px 0px'
            }}
          >
            <IconButton
              sx={{
                marginRight: '10px'
              }}
              onClick={() => {
                setAddFeatureOpen(false);
                setEditingFeature(null);
              }}
            >
              <CloseIcon
                sx={{
                  color: 'red'
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                addFeature(
                  { ASIN, feature: editingFeature },
                  {
                    onSuccess: () => {
                      setEditingId(null);
                      setAddFeatureOpen(false);
                      setEditingFeature('');
                      getFeatures();
                    }
                  }
                );
              }}
            >
              <DoneIcon
                sx={{
                  color: 'green'
                }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {(dealFeatures || []).map(({ feature, id }: DealFeatureType) => {
        if (editingId === id) {
          return (
            <Box key={id} sx={{}}>
              <FormControl margin="normal" fullWidth>
                <TextareaAutosize
                  minRows={3}
                  placeholder="Feature"
                  value={editingFeature}
                  onChange={(e) => {
                    setEditingFeature(e.target.value);
                  }}
                />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '10px 0px 10px 0px'
                }}
              >
                <IconButton
                  onClick={() => {
                    setEditingId(null);
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: 'red'
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    editFeature(
                      { id, feature: editingFeature },
                      {
                        onSuccess: () => {
                          setEditingId(null);
                          setEditingFeature('');
                          getFeatures();
                        }
                      }
                    );
                  }}
                >
                  <DoneIcon
                    sx={{
                      color: 'green'
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          );
        }
        return (
          <Box
            key={id}
            sx={{
              marginBottom: '10px'
            }}
          >
            <Typography variant="body1">{feature}</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '10px 0px 10px 0px'
              }}
            >
              <IconButton
                onClick={() => {
                  setEditingId(id);
                  setEditingFeature(feature);
                }}
              >
                <EditIcon
                  sx={{
                    color: 'purple'
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this feature?'
                    )
                  ) {
                    deleteFeature(
                      { id },
                      {
                        onSuccess: () => {
                          getFeatures();
                        },
                        onError: () => {
                          alert('Error deleting feature');
                        }
                      }
                    );
                  }
                }}
              >
                <DeleteIcon
                  sx={{
                    color: 'red'
                  }}
                />
              </IconButton>
            </Box>
            <Divider />
          </Box>
        );
      })}
    </div>
  );
};

/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import { useSnackbar } from '@hooks/useSnackbar';

export function useGetAIDescription() {
  interface RequestData {
    pageDealDescription?: string;
    pageDealName: string;
  }

  const mutationFn = async ({
    pageDealName,
    pageDealDescription
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post(`/api/dashboard/ai-description`, {
      pageDealName,
      pageDealDescription
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI');
    }
  });
}

export function useGetAIPageDealTitle() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    title: string;
  }

  const mutationFn = async ({
    title
  }: RequestData): Promise<{
    suggestions: string[];
  }> => {
    const response = await axios.post(`/api/dashboard/ai/get-page-deal-title`, {
      title
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: (err) => {
      showMessage(`Error getting AI suggestions ${err}`);
    }
  });
}

export function useGetAIPostText() {
  interface RequestData {
    ASIN: string;
  }

  const mutationFn = async ({
    ASIN
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post(`/api/dashboard/ai-post-text`, {
      ASIN
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI');
    }
  });
}

import React, { useState } from 'react';
import { Button, Box, Grid } from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';
import { useChangeDealLists } from '@hooks/useDealLists';
import ItemDealLists from './components/ItemDealLists';

const DealListChanger = ({ ASIN }: { ASIN: string }) => {
  const { data: user } = useGetUserData();
  const { showMessage } = useSnackbar();
  const [selectedDealListItems, setSelectedDealListItems] = useState<number[]>(
    []
  );
  const { mutate: changeDealLists } = useChangeDealLists();

  const handleSave = () => {
    changeDealLists(
      {
        ASIN,
        dealListIds: selectedDealListItems
      },
      {
        onSuccess: () => {},
        onError: () => {
          showMessage('Error saving deal lists');
        }
      }
    );
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  return (
    <Grid
      container
      sx={{
        mb: {
          xs: 2,
          sm: 0
        }
      }}
    >
      <Grid item xs={12} sm={9} md={10}>
        <ItemDealLists
          ASIN={ASIN}
          onChange={(values) => setSelectedDealListItems(values)}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Box
          display="flex"
          marginLeft="8px"
          sx={{
            justifyContent: {
              xs: 'flex-end',
              sm: 'initial'
            },
            marginTop: {
              xs: '0px',
              sm: '8px'
            }
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSave}
            size="small"
          >
            Update lists
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DealListChanger;

import React from 'react';
import Alert from '@mui/material/Alert';
import { formatDistance } from 'date-fns';
import { Box } from '@mui/material';
import { useGetHasBeenEmailed } from '@hooks/useCoupons';

export default function CouponHasBeenEmailed({
  promoCode
}: {
  promoCode: string;
}) {
  const { data } = useGetHasBeenEmailed(promoCode);
  const distance = data?.lastEmailedAt
    ? `at ${formatDistance(new Date(data?.lastEmailedAt), new Date())}.`
    : '.';

  return (
    <Box>
      {data?.hasBeenEmailed ? (
        <Alert severity="warning">
          Coupon has been emailed previously{distance}
        </Alert>
      ) : null}
    </Box>
  );
}

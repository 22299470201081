import { styled } from '@mui/material/styles';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const SpinningIcon = styled(AutorenewIcon, {
  shouldForwardProp: (prop) => prop !== 'isLoading'
})<{ isLoading: boolean }>(({ isLoading }) => ({
  animation: isLoading ? 'spin 1s linear infinite' : 'none',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

export default SpinningIcon;

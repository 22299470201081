export default [
  'Amazon has a deal right now on the',
  'For a limited time ONLY you can score a deal on the',
  'For a limited time ONLY you can score a cheap deal on the',
  'HURRY! For a limited time ONLY you can score a deal on the',
  'RUN! For a limited time ONLY you can score a cheap deal on the',
  'Hop over to Amazon and get a deal on the',
  'Hop on over to Amazon where you can right now get a deal on the',
  'Act fast to enjoy a special deal on the',
  'Take advantage of a low Amazon price on the',
  'Right now you can grab and low price on the',
  'While supplies last, head over to Amazon to claim a deal on the'
];

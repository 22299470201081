import React from 'react';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
import { iconButtonHoverRaiseStyle } from '../../../../utils/styles';

const SendMailingButton = ({
  appearance = 'button',
  handleSendMailingButtonClick
}: {
  handleSendMailingButtonClick: () => void;
  appearance?: 'button' | 'iconButton' | 'menuItem';
}) => {
  return (
    <>
      {appearance === 'button' && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMailingButtonClick}
        >
          Send Mailing
        </Button>
      )}
      {appearance === 'iconButton' && (
        <IconButton
          onClick={handleSendMailingButtonClick}
          sx={iconButtonHoverRaiseStyle}
        >
          <SendIcon />
        </IconButton>
      )}
      {appearance === 'menuItem' && (
        <MenuItem
          key="admin-switch-image"
          onClick={handleSendMailingButtonClick}
        >
          <ListItemIcon>
            <AttachEmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Send Mailing</ListItemText>
        </MenuItem>
      )}
    </>
  );
};

export default SendMailingButton;

import config from '@configFile';
import { DealPostType } from '@types';

import { addAffiliateTagToLink } from './formatUtils';

export const getAffiliateTagFromLink = (link: string): string | null => {
  const tagPatterns = [/\?tag=([^&]+)/, /&tag=([^&]+)/, /\/tag=([^&]+)/];

  for (let i = 0; i < tagPatterns.length; i++) {
    const match = link.match(tagPatterns[i]);
    if (match) {
      return match[1];
    }
  }

  return null;
};
export const getProductLink = (
  ASIN: string,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT,
  sLink: string = ''
) => {
  if (!sLink) {
    return `https://www.amazon.com/gp/product/${ASIN}/?fpl=fresh&psc=1&smid=ATVPDKIKX0DER&tag=${tag}`;
  }

  // If there is a link, we need to add the tag to the link
  return addAffiliateTagToLink(sLink, tag);
};

export const getNonAffiliateLink = (ASIN: string) => {
  return `https://www.amazon.com/gp/product/${ASIN}/`;
};

const buildCouponLink = (
  promoCode: string,
  tag: string,
  ASIN: string | null = null
): string => {
  const ASINPart = ASIN ? `&redirectAsin=${ASIN}` : '';
  return `https://www.amazon.com/promotion/psp/${promoCode}?ref=clp_external${ASINPart}&redirectMerchantId=ATVPDKIKX0DER&tag=${tag}`;
};

export const getCouponLink = (
  deal: DealPostType,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT
): string | null => {
  if (!deal?.promoCode) {
    return null;
  }
  return buildCouponLink(deal.promoCode, tag, deal.ASIN);
};

export const getCouponLinkFromCode = (
  promoCode: string,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT
): string => {
  return buildCouponLink(promoCode, tag);
};

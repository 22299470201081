/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Typography
} from '@mui/material';
import { stopPropagationForTab } from '@utils/index';

const SentModal = ({
  onCloseCallback = () => {},
  sentThumbnailUrl,
  scheduleAt
}: {
  onCloseCallback?: () => void;
  sentThumbnailUrl: string;
  scheduleAt: string;
}) => {
  const handleModalClickClose = () => {
    onCloseCallback();
  };

  return (
    <>
      <Dialog
        open
        onClose={() => {
          handleModalClickClose();
        }}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Mailing sent</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {scheduleAt
              ? 'Mailing has been scheduled successfully'
              : 'Mailing has been sent successfully'}
          </Typography>
          {sentThumbnailUrl ? (
            <img
              src={sentThumbnailUrl}
              alt="For the sent mailing"
              style={{
                maxHeight: '150px',
                maxWidth: '150px',
                margin: '20px auto 0 auto',
                display: 'block'
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleModalClickClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SentModal;

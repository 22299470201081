import _ from 'lodash';
import { startOfToday, addHours, isBefore } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const isUserInPSTTimeZone = () => {
  // Get the user's current timezone offset in minutes
  const currentUserOffset = new Date().getTimezoneOffset();

  // Los Angeles timezone offset in minutes for standard time (PST)
  // Note: This value might need to be adjusted for daylight saving time (PDT)
  const losAngelesOffset = 480; // PST is UTC-8

  // The getTimezoneOffset() method returns the difference, in minutes, between UTC and local time.
  // Note that this value is positive if the local timezone is behind UTC and negative if it is ahead.
  // Los Angeles (PST) is behind UTC, hence the positive offset.
  // Since the method returns a positive value for timezones behind UTC, we compare them directly.

  return currentUserOffset === losAngelesOffset;
};

export const fiveMinutesFromNow = new Date(new Date().getTime() + 5 * 60000);

// Suggests the next available time slot within the next 24 hours in the PST timezone
export const suggestTimeInPST = (): Date => {
  const timeSlots = [6, 8, 10, 14, 16, 18]; // Time slots in 24-hour format
  const timeZone = 'America/Los_Angeles';

  // Get the current date-time in PST
  const now = new Date();
  const nowInPST = utcToZonedTime(now, timeZone);
  const startOfTodayInPST = startOfToday();

  // Generate two sets of time slots for today and tomorrow to ensure the next 24 hours are covered
  const timeSlotsToday = timeSlots.map((slot) =>
    addHours(startOfTodayInPST, slot)
  );
  const timeSlotsTomorrow = timeSlots.map((slot) =>
    addHours(startOfTodayInPST, slot + 24)
  );

  // Combine and filter slots to only include those within the next 24 hours
  const allPossibleSlots = timeSlotsToday
    .concat(timeSlotsTomorrow)
    .filter((slot) => isBefore(nowInPST, slot));

  // Randomly select one of the available slots
  const randomSlot = _.sample(allPossibleSlots);

  // Convert the selected slot back to UTC
  const suggestedTimeUTC = zonedTimeToUtc(randomSlot, timeZone);
  return suggestedTimeUTC;
};

export const getMailingTimes = (): Date[] => {
  const timeSlots = [6, 8, 10, 14, 16, 18]; // Time slots in 24-hour format
  const timeZone = 'America/Los_Angeles';

  // Get the current date-time in PST
  const now = new Date();
  const nowInPST = utcToZonedTime(now, timeZone);
  const startOfTodayInPST = startOfToday();

  // Generate two sets of time slots for today and tomorrow to ensure the next 24 hours are covered
  const timeSlotsToday = timeSlots.map((slot) =>
    addHours(startOfTodayInPST, slot)
  );
  const timeSlotsTomorrow = timeSlots.map((slot) =>
    addHours(startOfTodayInPST, slot + 24)
  );

  // Combine and filter slots to only include those within the next 24 hours
  const allPossibleSlots = timeSlotsToday
    .concat(timeSlotsTomorrow)
    .filter((slot) => isBefore(nowInPST, slot));

  // Convert all available slots back to UTC and return them
  return allPossibleSlots.map((slot) => zonedTimeToUtc(slot, timeZone));
};

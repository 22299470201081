/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { format } from 'date-fns';

import { Box, Typography, Divider } from '@mui/material';
import { useGetMailingHistoryEnabled } from '@hooks/useSendMailing';
import { getMailingListNameFromId } from '../../../../utils/mailingLists';

const MailingHistory = ({
  ASIN,
  slug,
  promoCode
}: {
  ASIN?: string;
  slug?: string;
  promoCode?: string;
}) => {
  const { data: mailingHistory } = useGetMailingHistoryEnabled({
    ASIN,
    slug,
    promoCode
  });

  return (
    <Box>
      {mailingHistory && <Divider />}
      {(mailingHistory || []).map((mailing) => (
        <Typography
          component="div"
          variant="body2"
          key={mailing.id}
          style={{
            margin: '8px 0'
          }}
        >
          <div>
            <i>Last sent:</i>{' '}
            <strong>
              {format(
                new Date(mailing.createdAt),
                "EEEE, MMMM d, yyyy 'at' h:mm a"
              )}
            </strong>
          </div>
          <div>
            <i>Mailed to list:</i>{' '}
            <strong>{getMailingListNameFromId(mailing.sentToList)}</strong>
          </div>
          <Divider />
        </Typography>
      ))}
    </Box>
  );
};

export default MailingHistory;

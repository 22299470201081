import React, { useState } from 'react';
import {
  Collapse,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { formatDistance, format } from 'date-fns';
import { useGetDealLogs } from '@hooks/useDealLogs';
import { useUpdateDeal } from '@hooks/useUpdateDeal';
import SpinningIcon from '@components/SpinningIcon';
import config from '@configFile';
import { useSnackbar } from '@hooks/useSnackbar';
import { DealLog } from '../../../../types';

interface JsonViewerProps {
  jsonString: string;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ jsonString }) => {
  let parsedObject;

  try {
    parsedObject = JSON.parse(jsonString);
  } catch (error) {
    return (
      <Box>
        <Typography color="error">Invalid JSON string</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        backgroundColor: '#f5f5f5',
        whiteSpace: 'pre-wrap',
        overflowX: 'auto',
        fontSize: '0.8rem'
      }}
    >
      <pre>{JSON.stringify(parsedObject, null, 2)}</pre>
    </Box>
  );
};

const DealLogRow = ({ row }: { row: DealLog }) => {
  const [rowExpanded, setRowExpanded] = useState(false);
  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
        {row.message}
      </TableCell>
      <TableCell align="right" sx={{ verticalAlign: 'top' }}>
        {format(new Date(row.createdAt), 'PPPP p')} —{' '}
        {`Checked
${formatDistance(new Date(row.createdAt), new Date(), {
  addSuffix: true
})}`}
      </TableCell>
      <TableCell align="left">
        <Button
          onClick={() => {
            setRowExpanded(!rowExpanded);
          }}
        >
          {!rowExpanded ? 'Show details' : 'hide details'}
        </Button>
        <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
          <JsonViewer jsonString={row.meta} />
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default function DealLogs({ ASIN }: { ASIN: string }) {
  const {
    data: dealLogs,
    refetch: refetchDealLogs,
    isLoading,
    isFetching
  } = useGetDealLogs(ASIN);
  const [expanded, setExpanded] = useState(false);
  const { mutate: updateDeal } = useUpdateDeal();
  const { showMessage } = useSnackbar();

  return (
    <div>
      <Button
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        Show deal logs
      </Button>
      <Button
        startIcon={<SpinningIcon isLoading={isLoading || isFetching} />}
        onClick={() => {
          refetchDealLogs();
        }}
      >
        Refresh
      </Button>
      <Button
        onClick={() => {
          updateDeal(
            {
              update: {
                last_checked: new Date(
                  config.UPDATE_CONFIG.INACCURATE_RECHECK_TIME
                )
              },
              ASIN
            },
            {
              onSuccess: () => {
                showMessage('Deal recheck scheduled');
              }
            }
          );
        }}
      >
        Set to recheck
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Result</TableCell>
                <TableCell align="left">Checked at</TableCell>
                <TableCell align="left">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(dealLogs || [])?.map((row) => (
                <DealLogRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
}

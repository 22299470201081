import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';

import { Box, Button, FormControl, TextField, IconButton } from '@mui/material';
import { removeParagraphIndentation } from '@utils/index';

const PostText = ({
  ASIN,
  additionalPostText,
  setAdditionalPostText,
  otherMailingType,
  getAIPostText,
  isLoadingAIPostText,
  isUpdating,
  updatePostText
}: {
  ASIN: string;
  additionalPostText: string;
  setAdditionalPostText: (value: string) => void;
  otherMailingType: string | null;
  getAIPostText: () => void;
  isLoadingAIPostText: boolean;
  isUpdating: boolean;
  updatePostText: () => void;
}) => {
  return (
    <Box>
      <FormControl margin="normal" fullWidth>
        <TextField
          multiline
          placeholder="Post text"
          rows={additionalPostText?.length < 10 && !otherMailingType ? 1 : 10}
          value={removeParagraphIndentation(additionalPostText)}
          onChange={(e) => setAdditionalPostText(e.target.value)}
          autoComplete="off"
        />
      </FormControl>
      <Button onClick={getAIPostText}>
        {isLoadingAIPostText ? 'Loading...' : 'Get AI Post Text Suggestion'}
      </Button>
      {ASIN ? (
        <LoadingButton loading={isUpdating} onClick={updatePostText}>
          Update post text
        </LoadingButton>
      ) : null}
      <IconButton
        onClick={() => {
          setAdditionalPostText('');
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
};

export default PostText;

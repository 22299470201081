import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import { CSVRowsType } from '../types';

export function copyTextToClipboard(data: string): void {
  if (!navigator?.clipboard) {
    fallbackCopyTextToClipboard(data);
    return;
  }
  navigator?.clipboard.writeText(data).then(
    () => {
      // console.log('Async: Copying to clipboard was successful!');
    },
    (err) => {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

const fallbackCopyTextToClipboard = (data: string): void => {
  if (!window) return;
  // @ts-ignore
  if (window?.clipboardData && window?.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    // @ts-ignore
    window?.clipboardData.setData('Text', data);
  }
  if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea');
    textarea.textContent = data;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      return;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

interface OptionalShareParams {
  url?: string;
  title?: string;
}

export const shareUrl = (optional: OptionalShareParams = {}) => {
  const { url, title } = optional;
  const shareUrl = url || window?.location?.origin + window?.location?.pathname;
  const shareTitle = title || window?.document?.title;
  trackUse({
    item: 'share-url',
    value: `${shareUrl} - ${shareTitle}`,
    type: 'SHARE'
  });

  logPostHogEvent('share-url-mobile-icon-click', {
    value: `${shareUrl} - ${shareTitle}`,
    type: 'SHARE'
  });

  navigator
    ?.share({
      title: shareTitle,
      url: shareUrl
    })
    .then(() => console.log('Successful share'))
    .catch((error) => console.log('Error sharing', error));
};

export const isShareAPISupported =
  typeof window !== 'undefined' && typeof navigator !== 'undefined'
    ? !!navigator?.share
    : false;

export const stopPropagationForTab = (event: React.KeyboardEvent) => {
  // This is for a weird bug where something above this stops the e being typed
  event.stopPropagation();
};

export function exportCSV(
  headers: string[],
  rows: CSVRowsType,
  filename: string
): void {
  const csvContent = `data:text/csv;charset=utf-8,${headers
    .map((e) => `"${e}"`)
    .join(',')}\n${rows
    .map((row) => row.map((e) => `"${e}"`).join(','))
    .join('\n')}`;
  const encodedUri = encodeURI(csvContent.replace(/#/g, 'Number'));
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link); // Required for Firefox
  link.click();
}

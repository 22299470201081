import React, { useEffect } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import DealLogs from '@components/AdminActions/components/DealLogs';
import { useGetDealSummaryAdmin } from '@hooks/useGetDealSummary';
import {
  SeeStatsContentProps,
  Column,
  createRows
} from '@components/AdminActions/components/SeeStatsContent/utils';

const SeeStatsContent: React.FC<SeeStatsContentProps> = ({
  ASIN,
  maxHeight = 1000
}) => {
  const { data: dealSummaryAdmin, refetch } = useGetDealSummaryAdmin(ASIN);

  useEffect(() => {
    refetch();
  }, [refetch, ASIN]);

  const columns: readonly Column[] = [
    { id: 'name', label: 'Name', minWidth: 100 },
    { id: 'data', label: 'Data', minWidth: 100 }
  ];

  const rows = React.useMemo(
    () => createRows(dealSummaryAdmin),
    [dealSummaryAdmin]
  );

  const renderDealSummaryAdmin = () => {
    return (
      <>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: `${maxHeight}px` }}>
            <Table aria-label="admin table" size="small">
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <DealLogs ASIN={ASIN} />
      </>
    );
  };

  return (
    <>
      {dealSummaryAdmin ? (
        renderDealSummaryAdmin()
      ) : (
        <Typography variant="body1">Loading...</Typography>
      )}
    </>
  );
};

export default SeeStatsContent;

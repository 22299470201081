import React from 'react';
import {
  MenuItem,
  Box,
  Divider,
  Select,
  SelectChangeEvent,
  FormGroup
} from '@mui/material';

const SelectMainImage = ({
  value,
  onChange,
  ASIN,
  allImages
}: {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
  ASIN: string;
  allImages: string[];
}) => {
  const mainImage = value;
  return (
    <Box>
      {mainImage ? (
        <img
          src={mainImage}
          alt={`For ${ASIN}`}
          key={mainImage}
          style={{
            maxHeight: '150px',
            maxWidth: '150px',
            margin: '5px'
          }}
        />
      ) : null}
      <FormGroup
        sx={{
          display: 'block',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: '12px'
        }}
      >
        <Select
          labelId="email-image-select"
          id="email-image-main-select"
          label="Main image"
          value={mainImage || ''}
          onChange={onChange}
        >
          {(allImages || []).map((image, idx) => (
            <MenuItem key={image} value={image}>
              Image {idx + 1}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
      <Divider />
    </Box>
  );
};

export default SelectMainImage;

/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import {
  useQuery,
  UseQueryResult,
  useMutation
  // useQueryClient
} from 'react-query';
import { OCRImageData } from '@types';
import { useSnackbar } from '@hooks/useSnackbar';
import queryClient from '../queryClient';

export const useGetOCRImages = (
  ASIN: string
): UseQueryResult<OCRImageData[]> => {
  const queryOCRImages = async (ASIN: string): Promise<OCRImageData[]> => {
    const result = await axios.get(
      `/api/dashboard/get-ocr-for-image?ASIN=${ASIN}`
    );
    return result.data;
  };

  return useQuery<OCRImageData[], Error>(
    `ocr-images-${ASIN}`,
    () => queryOCRImages(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching the OCR images');
      }
    }
  );
};

interface RequestDataOCRBanned {
  ASIN: string;
  isBanned: boolean;
  image: string;
}

const mutationOCRBannedFn = async ({
  ASIN,
  isBanned,
  image
}: RequestDataOCRBanned): Promise<null> => {
  return axios.post(`/api/dashboard/set-ocr-image-banned`, {
    ASIN,
    isBanned,
    image
  });
};

export const setOCRImageAsBanned = () => {
  return useMutation(mutationOCRBannedFn, {
    onSuccess: (_res, { ASIN }) => {
      queryClient.invalidateQueries(`ocr-images-${ASIN}`);
      queryClient.invalidateQueries(`deal-summary-${ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
      queryClient.invalidateQueries('latest-deals-db');
    },
    onError: () => {
      console.error('error setting image');
    }
  });
};

interface ResultData {
  high_res_images: string;
}

const queryProductImages = async (ASIN: string): Promise<ResultData> => {
  const result = await axios.get(
    `/api/dashboard/getProductImages?ASIN=${ASIN}`
  );
  return result.data;
};

export function useGetItemImages(ASIN: string): UseQueryResult<ResultData> {
  return useQuery<ResultData, Error>(
    `product-images-${ASIN}`,
    () => queryProductImages(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching the product images');
      }
    }
  );
}

export function setItemImage() {
  interface RequestData {
    image: string;
    ASIN: string;
  }
  const { showMessage } = useSnackbar();
  const mutationFn = async ({ ASIN, image }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/setProductImage`, {
      ASIN,
      image
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_res, { ASIN }) => {
      queryClient.invalidateQueries(`ocr-images-${ASIN}`);
      queryClient.invalidateQueries(`deal-summary-${ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
      queryClient.invalidateQueries('latest-deals-db');
      showMessage('Image set successfully');
    },
    onError: () => {
      console.error('error setting image');
    }
  });
}

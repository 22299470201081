/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { FormGroup } from '@mui/material';
import { isImageBanned } from '@utils/index';
import { useGetOCRImages } from '@hooks/useItemImages';

const bannedImageStyles = {
  '-webkit-filter': 'grayscale(100%)',
  filter: 'grayscale(100%)'
};

const SelectAdditionalImages = ({
  ASIN,
  uniqueImages,
  additionalImages,
  setAdditionalImages,
  mainImage
}: {
  ASIN: string;
  uniqueImages: string[];
  additionalImages: string[];
  setAdditionalImages: (images: string[]) => void;
  mainImage: string;
}) => {
  const { data: ocrImages } = useGetOCRImages(ASIN);

  return (
    <FormGroup
      sx={{
        display: 'block',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '12px'
      }}
    >
      {uniqueImages
        .filter((im) => {
          return im !== mainImage;
        })
        .map((image: string) => (
          <img
            src={image?.replace('http:', 'https:')}
            alt={`For ${ASIN}`}
            key={image}
            style={{
              maxHeight: '150px',
              maxWidth: '150px',
              margin: '5px',
              border: additionalImages.includes(image)
                ? '3px solid purple'
                : '3px solid white',
              ...(isImageBanned(image, ocrImages || [])
                ? bannedImageStyles
                : {})
            }}
            onClick={() => {
              if (additionalImages.includes(image)) {
                setAdditionalImages(
                  additionalImages.filter((img) => img !== image)
                );
              } else {
                setAdditionalImages([...additionalImages, image]);
              }
            }}
          />
        ))}
    </FormGroup>
  );
};

export default SelectAdditionalImages;
